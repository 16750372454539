import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/4cd8a442/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <p>{`My interest in mathematics started in primary school. I hid my reading
comprehension book in the teachers' cupboard so I could spend more time in my maths book.
It was A6, had a thin card beige cover and square paper within. I remember Mr Platt setting me
the following problem:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If a runner can run 100 metres in 10 seconds, how long does it take her to run 200 metres?`}</p>
    </blockquote>
    <p>{`I enthusiatically answered "20 seconds!" and awaited my praise.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Won't she get tired?`}</p>
    </blockquote>
    <p>{`Oh... `}</p>
    <p>{`It was my first experience of my mathematical model of the world breaking down. I felt
a bit betrayed at this point. I had spent a fair bit of time on this stuff, and I thought I
had the answer to everything. I was somewhat mollified when Mr Platt introduced me to the
concept of acceleration, but it would be many years before I learned the mathematics. `}</p>
    <p>{`At secondary school I chose mechanics over statistics which seemed too wishy-washy, whereas
mechanics seemed to follow strict constructionist rules. `}<em parentName="p">{`Learn Newton's three laws of motion
and apply ad infinitum.`}</em>{` (He he.) I wrote up all my A-Level notes in Lotus Notes on an IBM computer my
dad had brought back from work. I didn't know how to add the symbol `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`θ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\theta`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.69444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`θ`}</span></span></span></span></span>{` so every time I
needed one I would use 0 and draw a horizontal line through it. There are a `}<em parentName="p">{`lot`}</em>{` of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`θ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\theta`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.69444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`θ`}</span></span></span></span></span>{`s
in A-Level mechanics.`}</p>
    <p>{`That was enough to get me through school and into Bristol University where mathematics seemed
to change all over again. Mechanics was suddenly Classical, Hamiltonian or Quantum. Pure maths
was now Analysis, Logic, Group Theory, Algebra, Geometry and Calculus. Statistics was now
Frequentist Statistics and Probability.`}</p>
    <p>{`I found some solace in Euclid's Elements, starting with a few obvious truths and building up a
toolbox of geometrical theorems. One of those obvious truths is the parallel postulate:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If a line segment intersects two straight lines forming two interior angles on the same side
that sum to less than two right angles, then the two lines, if extended indefinitely, meet on
that side on which the angles sum to less than two right angles.`}</p>
    </blockquote>
    <p>{`(Basically parallel lines don't meet.) Well that seems pretty reasonable but it turns out that
if you assume this is not true you get a whole new field called Non-Euclidean Geometry.`}</p>
    <p>{`After surviving my third year by choosing the subjects with the best lecturers, and a brief
dalliance with aeronautical engineering, I started a `}<a parentName="p" {...{
        "href": "./phd.pdf"
      }}>{`PhD`}</a>{` in statistics. Obviously
not using the Frequentist statistics I had studied in my undergraduate but Bayesian statistics
which derides the Confidence Intervals and p-values of Frequentists and rebuilds statistics on a
foundation of `}<em parentName="p">{`degrees of belief`}</em>{`. `}</p>
    <p>{`I completed the PhD, ceremoniously burned years of work, and started work in edtech
on the visualization of mathematical concepts taught in secondary schools. Then I co-founded
`}<a parentName="p" {...{
        "href": "https://www.diagnosticquestions.com"
      }}>{`Diagnostic Questions`}</a>{` and later `}<a parentName="p" {...{
        "href": "https://www.eedi.co.uk"
      }}>{`Eedi`}</a>{`
where we use Machine Learning to solve our big data problems. (Machine Learning developed
within the computer science community, whilst Frequentist and Bayesian statisticians were
distracted fighting over the use of subjectivity in data models.)`}</p>
    <p>{`From the time of assuming a runner could go the same speed forever, I have loved learning
new topics in mathematics, learning the limitations and learning another. I am passionate about
the visualisation and synthesis of mathematics, and I am currently studying
`}<a parentName="p" {...{
        "href": "https://faculty.marshall.usc.edu/gareth-james/ISL/"
      }}>{`Statistical Learning`}</a>{` with the aim of
understanding the relationship between Machine Learning and Statistics.`}</p>
    <p>{`If you want to talk maths or data analysis feel free to get in touch with me by `}<a parentName="p" {...{
        "href": "mailto:m%65@%73imonwoo%64%68%65ad.c%6F%6D"
      }}>{`email`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      